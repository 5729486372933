import React, { useEffect } from "react";

import { Link, navigate } from "gatsby";

const Filters = (props) => {
  //PROPS
  const {
    currentLocale,
    skillFilter,
    toggleMenu,
    setSkillFilter,
    translations,
    isHome
  } = props;
  //PROPS

  //DATA
  //para ter realizadores antes de fotógrafos
  const edges = props?.data?.allWpSkill?.edges;
  edges && edges.sort((a, b) => (a.node.slug > b.node.slug ? 1 : -1));
  //DATA

  useEffect(() => {
    let isNot = props.data.page.work_info || props.data.page.talents_info;

    if (!isNot) {
      setSkillFilter("");
    }
  }, [translations]);

  return (
    <ul>
      {edges &&
        edges.map(
          (item, index) =>
            item.node.language.code === currentLocale && (
              <li
                key={index}
                className={skillFilter === item.node.slug ? "active" : ""}
              >
                <button
                  onClick={() => {
                    toggleMenu(false);
                    if (isHome && skillFilter !== item.node.slug) {
                      setSkillFilter(item.node.slug);
                      navigate(
                        currentLocale === "PT" ? "/#showreel" : "/en#showreel"
                      );
                    } else if (!isHome) {
                      setSkillFilter(item.node.slug);
                      navigate(
                        currentLocale === "PT" ? "/#showreel" : "/en#showreel",
                        {
                          state: {
                            skillFilter: item.node.slug
                          }
                        }
                      );
                    }
                  }}
                >
                  {item.node.name === "Director"
                    ? "Directors"
                    : item.node.name === "Realizador"
                    ? "Realizadores"
                    : item.node.name === "Fotógrafo"
                    ? "Fotógrafos"
                    : item.node.name === "Photographer"
                    ? "Photographers"
                    : null}
                </button>
              </li>
            )
        )}
      {!isHome ? (
        <li id="latestLi">
          <Link to="/#latest">Latest</Link>
        </li>
      ) : (
        <li id="latestLi">
          <a
            href="#latest"
            onClick={() => {
              toggleMenu(false);
            }}
          >
            Latest
          </a>
        </li>
      )}
    </ul>
  );
};

export default Filters;
