import { css } from "styled-components";
import { toRem, breakpoints } from "utils/mixins";

export const h1 = css`
  font-family: "Sneak", sans-serif;
  font-size: ${toRem(50)};
  font-weight: 500;
  line-height: normal;
  @media (orientation: portrait) {
    font-size: ${toRem(45)};
  }
  @media (max-width: 599px) {
    font-size: ${toRem(40)};
  }
`;

export const h2 = css`
  font-family: "Sneak", sans-serif;
  font-size: ${toRem(50)};
  font-weight: 500;
  letter-spacing: -1.5px;
  line-height: ${toRem(60)};
  @media ${breakpoints.bigDesktop} {
    font-size: ${toRem(60)};
    line-height: ${toRem(70)};
  }
  @media (orientation: portrait) {
    font-size: ${toRem(40)};
    line-height: ${toRem(50)};
    letter-spacing: -2.5px;
  }
  @media (max-width: 599px) {
    font-size: ${toRem(30)};
    line-height: ${toRem(40)};
  }
`;

export const h3 = css`
  font-family: "Sneak", sans-serif;
  font-size: ${toRem(28)};
  font-weight: 500;
  margin-bottom: ${toRem(10)};
  line-height: ${toRem(31)};
  @media (orientation: portrait) {
    font-size: ${toRem(25)};
    line-height: ${toRem(27)};
  }
  @media (max-width: 599px) {
    font-size: ${toRem(20)};
    line-height: ${toRem(22)};
  }
`;

export const h4 = css`
  font-family: "Sneak", sans-serif;
  font-size: ${toRem(20)};
  font-weight: 500;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: ${toRem(12)};
  line-height: ${toRem(22)};
  @media (orientation: portrait) {
    font-size: ${toRem(15)};
    line-height: ${toRem(18)};
  }
  /* @media (max-width: 599px) {
    font-size: ${toRem(14)};
    line-height: ${toRem(16)};
  } */
`;

export const h5 = css`
  font-family: "Sneak", sans-serif;
  font-size: ${toRem(16)};
  font-weight: 500;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: ${toRem(12)};
  font-weight: 400;
  letter-spacing: -0.666667px;
  line-height: ${toRem(18)};
  @media (orientation: portrait) {
    font-size: ${toRem(16)};
    line-height: ${toRem(18)};
  }
  @media (max-width: 599px) {
    font-size: ${toRem(16)};
    line-height: ${toRem(18)};
  }
`;

export const menuItem = css`
  font-family: "Sneak", sans-serif;
  font-size: ${toRem(16)};
  font-weight: 500;
  text-transform: uppercase;
  line-height: ${toRem(17)};
  letter-spacing: -0.67px;
  @media (max-width: 1079px) and (orientation: landscape) {
    font-size: ${toRem(32)};
    line-height: ${toRem(33)};
  }
  @media (max-width: 1079px) and (orientation: portrait) {
    letter-spacing: -1.5px;
    font-size: ${toRem(60)};
    line-height: ${toRem(61)};
  }
  @media (max-width: 699px) {
    letter-spacing: -1.5px;
    font-size: ${toRem(50)};
    line-height: ${toRem(51)};
  }
  @media (max-width: 599px) {
    letter-spacing: -1.5px;
    font-size: ${toRem(36)};
    line-height: ${toRem(37)};
  }
  @media (min-width: 1921px) {
    font-size: 1.7vh;
    line-height: 1.8vh;;
  }
`;

export const p = css`
  font-family: "Sneak", sans-serif;
  font-weight: 300;
  font-size: ${toRem(16)};
  line-height: ${toRem(17)};
  text-align: justify;
  margin: 0;
  margin-bottom: ${toRem(8)};
  text-align-last: center;
  @media (orientation: portrait) {
    font-size: ${toRem(13)};
    line-height: ${toRem(15)};
  }
  @media ${breakpoints.forTablet} {
    font-size: ${toRem(14)};
    line-height: ${toRem(16)};
  }
`;
