import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";

import { toRem } from "utils/mixins";
import { breakpoints } from "utils/breakpoints";
import { white, black } from "./colors";
import { container } from "./sizes";
import { p, h1, h2, h3, h4, fontImport } from "./typography";

export const GlobalStyles = createGlobalStyle`
  ${normalize}

  ${fontImport}

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%;
  }

  html,
  body {
    margin: 0;
    padding: 0;
    background: ${white.default};
    cursor: default;
    overflow-x: hidden;
    @media ${breakpoints.forLaptop} {
      overflow-x: hidden
    }
    &.noover {
      overflow-y: hidden;
    }
  }

  body {
    position: relative;
    overflow-x: hidden;
    font-family: "Sneak", sans-serif;
    font-weight: 300;
    font-size: ${toRem(16)};
    line-height: 1.4;
    -webkit-font-smoothing: antialiased;
    color: black;
    -moz-osx-font-smoothing: grayscale;
  }

  *::-webkit-media-controls-panel {  display: none!important;  -webkit-appearance: none;}
  
  *::--webkit-media-controls-play-button {  display: none!important;  -webkit-appearance: none;}
  
  *::-webkit-media-controls-start-playback-button {  display: none!important;  -webkit-appearance: none;}

  .svg-anim{
      transition: 250ms;
    *{
      transition: 250ms;
    }
  }

  .return-section {
    position: absolute;
    top: 0;
    margin:${toRem(90)} 0 0;
    z-index: 4;
    button {
      padding:${toRem(20)};
    }
    svg {
      transform: rotate(180deg)
    }
    @media ${breakpoints.forTablet} {
      margin:${toRem(120)} 0 0;
    }
    @media ${breakpoints.forLaptop} {
      margin:${toRem(90)} ${toRem(25)} 0;
    }
    @media ${breakpoints.forDesktop} {
      margin:${toRem(120)} ${toRem(43)} 0;
    }
    @media ${breakpoints.forBigDesktop} {
      margin: 18vh calc(6vh - 20px) 0;
    }
  }

  .main {
    padding-top: ${toRem(160)};
    min-height: calc(100vh - 136px);
    position: relative;

    &--flex {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &--center{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      h1 {
        margin-bottom: 22px;
        font-size: 54px;
        line-height: 54px;  
        position: relative;
        &:after {
          content: "";
          position: absolute;
          width: 100%;
          height: ${toRem(4)};
          display: block;
          bottom: 0;
          right: 0;
          background-color: ${black.default};
          transition: 0.4s ease;
        }
      }
    
    }

    &--nopad {
      padding-top: 0;
   //   padding-bottom: ${toRem(25)};
    }

    @media ${breakpoints.forTablet} {
      padding-top: ${toRem(190)};
      &--nopad {
        padding-top: 0;
      }
    }
    @media ${breakpoints.forLaptop} {
      padding-top: ${toRem(160)};
      &--nopad {
        padding-top: 0;
      }
    }
    @media ${breakpoints.forDesktop} {
      padding-top: ${toRem(190)};
      &--nopad {
        padding-top: 0;
      }
    }
    @media ${breakpoints.forBigDesktop} {
      padding-top: 18vh;
      &--nopad {
        padding-top: 0;
      }
    }
  }

  .withA {
    a {
      display: inline;
      position: relative;
      cursor: pointer;
      transition: 250ms;
      &:after {
        content: "";
        position: absolute;
        width: 0;
        height: ${toRem(1)};
        display: block;
        margin-top: 0;
        right: 0;
        background-color: black;
        transition: 0.4s ease;
      }
      &:hover {
        &:after {
          width: 100%;
          left: 0;
        }
      }
    }

    @media ${breakpoints.untilLaptop} {
      a { 
        &:after {
          width: 100%;
          left: 0;
        }
      }
    }
  }

  .latest{
    padding-top: ${toRem(160)}; 
    .h1-underline {
      margin-bottom: 70px;
    }   
    @media ${breakpoints.forTablet} {
      padding-top: ${toRem(190)};
    }
    @media ${breakpoints.forLaptop} {
      padding-top: ${toRem(160)};   
      .h1-underline {
        margin-bottom: 0;
     } 
    }
    @media ${breakpoints.forDesktop} {
      padding-top: ${toRem(190)};
    }
    @media ${breakpoints.forBigDesktop} {
      padding-top: 15vh;
    }
  }

  .spt  {
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    p {
      font-size: 15px!important;
    }
  }

  .contact-text {
    p {
      font-size: 15px;
      margin-bottom: 6px;
    }
  }

  .container {
    width: 100%;
    max-width: ${container.default};
    margin: 0 auto;
    position: relative;
    padding-left: ${toRem(20)};
    padding-right: ${toRem(20)};

    &--small {
      max-width: ${container.small};
    }   

    &--xsmall {
      max-width: ${container.xsmall};
    }
    
    &--center {
      text-align: center;
      p {
        text-align: center;
      }
      h4 a{
        text-decoration: underline;
      }
    }

    &--justify {
      text-align: justify;
      text-align-last: unset;
      p {
        text-align: justify;
        text-align-last: unset;
      }
    }

    &--800 {
      max-width: ${toRem(800)};
      margin : ${toRem(12)} auto ;
      .smedia__container {
        margin-top: ${toRem(8)};
        align-items: center;
        justify-content: center;
        display: flex;
        li {
          margin: 0 ${toRem(8)};
          line-height: ${toRem(10)};
          img {
            width: ${toRem(22)};
          }
        }
      }
    }

    &--bio{
      margin : ${toRem(90)} auto ${toRem(12)};
      .smedia__container {
        padding-top: 6px;
      }
    }

  }

  .project-info{
    margin-top: ${toRem(20)};
    .h1-underline {
      font-size: 28px;
      line-height: 31px;
      letter-spacing: -0.5px;
      margin-bottom: ${toRem(7)};
    }
    h4 {
      margin : 0;
      margin-top: ${toRem(20)};
      margin-bottom: ${toRem(7)};
      font-weight: 300;
      line-height: 24px;
      text-align: center;
      letter-spacing: -0.909091px;
      text-transform: unset;
    }
    h3 {
      ${h4}
      
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      /* identical to box height */

      text-align: center;
      letter-spacing: -0.91px;
      text-decoration-line: underline;
      text-transform: uppercase;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-weight: 400;
  }

  

  h1, .h1 {
    ${h1}
    color: black;
    text-align: center;
    text-transform: uppercase;
    transition: 250ms;
    &.h1-underline {
      text-decoration: underline;
      margin: 0;
      margin-bottom: ${toRem(40)};
      &--72 {
        margin-bottom: ${toRem(72)};
      }
      &--nom{
        
      margin-bottom: 0;
      }
    }
  }

  h2 {
    ${h2}
    color: black;
    text-align: center;
    text-transform: uppercase;
    margin: ${toRem(12)};
    transition: 250ms;
  }

  h3, .h3 {
    ${h3}
  }

  h4 {
    ${h4}
  }

  p {
    ${p}
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  img {
    max-width: 100%;
  }

  a {
    color: inherit;
    text-decoration: none;
    transition: 250ms;
    &:hover {
      /* opacity: 0.2; */
    }
  }

  button {
    appearance: none;
    display: inline-block;
    border-radius: 0;
    background: none;
    outline: none;
    box-shadow: none;
    border: none;
    padding: 0;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    cursor: pointer;
  }

  address {
    font-style: normal;
  }

  p {
    margin-bottom: 1em;
  }

  .show {
    display: block;
  }

  .hidden {
    display: none;
  }

  .hide__mobile {
    @media (max-width: 1079px) {
      display: none;
    }
  }

  .show__mobile {
    display: none;
    @media (max-width: 1079px) {
      display: block;
    }
  }
  .abs_hide {
    position: absolute;
    left: -5000px;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }
  .underline {
    text-decoration: underline;
    padding-right: ${toRem(50)};
    padding-top: ${toRem(20)};
  }
  .under {
    text-decoration: underline;
  }
`;
