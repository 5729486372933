import React from "react";

import { Link, useStaticQuery, graphql } from "gatsby";

const Menu = (props) => {
  //PROPS
  const {
    currentLocale,
    data: {
      page: { id }
    }
  } = props;
  //PROPS

  //DATA
  const data = useStaticQuery(graphql`
    query Menu {
      allWpMenu {
        edges {
          node {
            menuItems {
              nodes {
                label
                url
                connectedNode {
                  node {
                    id
                  }
                }
              }
            }
            name
          }
        }
      }
    }
  `);

  const {
    allWpMenu: { edges }
  } = data;
  //DATA

  //DEFS
  let info = edges.filter((item) =>
    item.node.name.search("Main Menu Lang:" + currentLocale) !== -1
      ? item
      : null
  );

  let menu = info[0].node.menuItems.nodes;
  //DEFS

  return (
    <ul>
      {menu.map((item, index) => {
        //   console.log(item,id, item.connectedNode.node.id);
        return (
          <li
            key={index}
            className={id === item.connectedNode.node.id ? "active" : ""}
          >
            <Link to={item.url}>{item.label}</Link>
          </li>
        );
      })}
    </ul>
  );
};

export default Menu;
