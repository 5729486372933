import React from "react";

const SadFace = (props) => {
  
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.0991 37.9209C13.1061 37.9209 5.78911 30.6039 5.78911 21.6099C5.78911 12.6169 13.1061 5.2999 22.0991 5.2999C31.0931 5.2999 38.4101 12.6169 38.4101 21.6099C38.4101 30.6039 31.0931 37.9209 22.0991 37.9209ZM22.0991 -9.91821e-05C10.1641 -9.91821e-05 0.489105 9.6749 0.489105 21.6099C0.489105 33.5459 10.1641 43.2209 22.0991 43.2209C34.0351 43.2209 43.7091 33.5459 43.7091 21.6099C43.7091 9.6749 34.0351 -9.91821e-05 22.0991 -9.91821e-05Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.9731 19.7353C29.4631 19.7353 30.6711 18.5263 30.6711 17.0363C30.6711 15.5463 29.4631 14.3383 27.9731 14.3383C26.4831 14.3383 25.2751 15.5463 25.2751 17.0363C25.2751 18.5263 26.4831 19.7353 27.9731 19.7353Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5273 17.0361C13.5273 18.5261 14.7353 19.7351 16.2253 19.7351C17.7153 19.7351 18.9233 18.5261 18.9233 17.0361C18.9233 15.5461 17.7153 14.3381 16.2253 14.3381C14.7353 14.3381 13.5273 15.5461 13.5273 17.0361Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.0996 24.2148C18.2936 24.2148 14.9146 26.0458 12.7716 28.8638C13.4226 29.6998 14.1826 30.4438 15.0326 31.0778C16.5816 28.8318 19.1706 27.3558 22.0996 27.3558C25.0286 27.3558 27.6166 28.8318 29.1666 31.0778C30.0156 30.4438 30.7756 29.6998 31.4276 28.8638C29.2846 26.0458 25.9056 24.2148 22.0996 24.2148Z"
        fill="black"
      />
    </svg>
  );
};

export default SadFace;
