import React, { useEffect, useState } from "react";

import { black, white } from "theme/colors";
import { Arrow } from "components/icons";
import {
  FooterWrapper,
  Smedia,
  Newsletter,
  Copy
} from "./SocialMediaFooter.styles";
import { getYear } from "utils/mixins";

import { useCookies } from "react-cookie";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { Facebook, Instagram, LinkedIn } from "../icons";

const CustomForm = ({ locale, status, message, onValidated, isBlack }) => {
  let email;

  const [st, setStatus] = useState(false);

  useEffect(() => {
    setStatus(status);
  }, [status]);

  const submit = () =>
    email &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value
    });

  return (
    <Newsletter isBlack={isBlack}>
      <div className="news_container">
        <input
          placeholder="Subscribe to our newsletter"
          type="email"
          onChange={() => setStatus(false)}
          ref={(node) => (email = node)}
        ></input>
        <button aria-label="Go back" onClick={submit}>
          <Arrow color={isBlack ? black.default : white.default} />
        </button>
        {st === "sending" && (
          <div className="news_feedback">
            {locale === "EN" ? "Sending..." : "A enviar..."}
          </div>
        )}
        {st === "success" && (
          <div className="news_feedback">
            {locale === "EN"
              ? "Successfully added."
              : "Adicionado(a) com sucesso."}
          </div>
        )}
        {st === "error" && (
          <div className="news_feedback">
            {locale === "EN"
              ? "An error occurred, maybe you are already subscribed."
              : "Ocorreu um erro, pode ser que ja esteja registado."}
          </div>
        )}
      </div>
    </Newsletter>
  );
};

const SocialMediaFooter = (props) => {
  //PROPS
  const { locale, isBlack, showFooter, dimensions } = props;
  //PROPS

  //FUNCS

  const url =
    locale === "EN"
      ? "https://theplayground.us10.list-manage.com/subscribe/post?u=cd86c8707ec4f06a646b6d631&amp;id=e2d6966151"
      : "https://theplayground.us8.list-manage.com/subscribe/post?u=63c45bf1702020577b6417971&amp;id=bf1bcd75c3";
  //FUNCS

  const [cookies, setCookie] = useCookies(["hasCookiesPlay"]);

  let mobileCookies =
    dimensions.width <= 699 && cookies.hasCookiesPlay !== "true";

  return (
    <FooterWrapper
      showFooter={showFooter}
      isHome={props.isHome}
      isTal={props.isTalentPage}
      isBlack={isBlack}
    >
      {!mobileCookies && (
        <>
          <MailchimpSubscribe
            url={url}
            render={({ subscribe, status, message }) => (
              <CustomForm
                locale={locale}
                isBlack={isBlack}
                status={status}
                message={message}
                onValidated={(formData) => subscribe(formData)}
              />
            )}
          />
          <Copy>
            {cookies.hasCookiesPlay === "true"
              ? "© " + getYear() + " PLAYGROUND - Production Company. coded by Others"
              : ""}
          </Copy>
          <Smedia>
            <li>
              <a
                href="https://www.facebook.com/PlaygroundLisboa"
                aria-label="Social Media Facebook Playground"
                target="_blank"
                rel="noreferrer"
              >
                <Facebook color={isBlack ? black.default : white.default} />
              </a>
            </li>

            <li>
              <a
                target="_blank"
                href="https://www.instagram.com/theplaygroundpt/"
                aria-label="Social Media Instagram Playground"
                rel="noreferrer"
              >
                <Instagram color={isBlack ? black.default : white.default} />
              </a>
            </li>

            <li>
              <a
                target="_blank"
                href="https://www.linkedin.com/company/playground-production-company/"
                aria-label="Social Media LinkedIn Playground"
                rel="noreferrer"
              >
                <LinkedIn color={isBlack ? black.default : white.default} />
              </a>
            </li>
          </Smedia>
        </>
      )}
    </FooterWrapper>
  );
};

export default SocialMediaFooter;
