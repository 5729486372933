// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-custom-404-template-js": () => import("./../../../src/templates/custom/404Template.js" /* webpackChunkName: "component---src-templates-custom-404-template-js" */),
  "component---src-templates-custom-contacts-template-js": () => import("./../../../src/templates/custom/ContactsTemplate.js" /* webpackChunkName: "component---src-templates-custom-contacts-template-js" */),
  "component---src-templates-custom-homepage-template-js": () => import("./../../../src/templates/custom/HomepageTemplate.js" /* webpackChunkName: "component---src-templates-custom-homepage-template-js" */),
  "component---src-templates-custom-privacy-policy-template-js": () => import("./../../../src/templates/custom/PrivacyPolicyTemplate.js" /* webpackChunkName: "component---src-templates-custom-privacy-policy-template-js" */),
  "component---src-templates-custom-shooting-in-portugal-template-js": () => import("./../../../src/templates/custom/ShootingInPortugalTemplate.js" /* webpackChunkName: "component---src-templates-custom-shooting-in-portugal-template-js" */),
  "component---src-templates-custom-work-services-template-js": () => import("./../../../src/templates/custom/WorkServicesTemplate.js" /* webpackChunkName: "component---src-templates-custom-work-services-template-js" */),
  "component---src-templates-single-new-js": () => import("./../../../src/templates/single/New.js" /* webpackChunkName: "component---src-templates-single-new-js" */),
  "component---src-templates-single-post-js": () => import("./../../../src/templates/single/post.js" /* webpackChunkName: "component---src-templates-single-post-js" */),
  "component---src-templates-single-talent-js": () => import("./../../../src/templates/single/Talent.js" /* webpackChunkName: "component---src-templates-single-talent-js" */)
}

