export const breakpoints = {
  untilLaptop: `(max-width: 1079px)`,
  forBigMobile: `(min-width: 399px)`,
  forTablet: `(min-width: 599px)`,
  forBigTablet: `(min-width: 699px)`,
  forLaptop: `(min-width: 1079px) and (orientation: landscape)`,
  forDesktop: `(min-width: 1439px)`,
  forBigDesktop: `(min-width: 1921px)`
};

export const zIndex = {
  entry: 0,
  base: 1,
  mid: 10,
  more: 20,
  ultra: 30,
  god: 40
};
