import React from "react";

import { Link, useStaticQuery, graphql } from "gatsby";

const Lang = (props) => {
  //PROPS
  const { translations, currentLocale } = props;
  //PROPS

  //DATA
  const data = useStaticQuery(graphql`
    query langs {
      allWp {
        edges {
          node {
            languages {
              code
            }
          }
        }
      }
    }
  `);

  const {
    allWp: { edges }
  } = data;
  //DATA

  //DEFS
  let langs = edges[0].node.languages;
  //DEFS

  return (
    <>
      {langs.map((item, index) => {
        if (item.code !== currentLocale) {
          return (
            <Link to={translations.uri} className="lang-item" key={index}>
              {item.code}
            </Link>
          );
        } else {
          return null;
        }
      })}
    </>
  );
};

export default Lang;
