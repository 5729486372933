import React, { useEffect, useState } from "react";

import { Link } from "gatsby";
import gsap from "gsap";

import {
  HeaderContainer,
  MobileMenu,
  Smedia,
  Toggle,
  LogoContainer
} from "./Header.styles";

import Filters from "./filters";
import Menu from "./menu";
import Lang from "./lang";

import { Facebook, Instagram, LinkedIn } from "components/icons";
import * as icons from "components/icons";
import { black, white } from "theme/colors";
import { getYear } from "utils/mixins";

const Header = (props) => {
  //PROPS
  const {
    currentLocale,
    skillFilter,
    setSkillFilter,
    data,
    dimensions,
    translations,
    isBlack,
    pID,
    isBG,
    setBlack,
    isHome,
    setScroll
  } = props;
  //PROPS

  const tl = gsap.timeline();

  //STATE
  const [showMenu, toggleMenu] = useState(false);
  //STATE

  //EFFECTS
  useEffect(() => {
    toggleMenu(false);
    return function cleanup() {
      setBlack(true);
    };
  }, [translations, data]);

  useEffect(() => {
    if (showMenu) {
      tl.to(`#mobile-menu`, {
        duration: 0.25,
        opacity: 1,
        pointerEvents: "unset"
      }).to(`.mobile__content`, {
        duration: 0.35,
        opacity: 1
      });

      setScroll(true);
    } else {
      // setBlack(false);
      tl.to(`.mobile__content`, {
        duration: 0.35,
        opacity: 0
      }).to(`#mobile-menu`, {
        duration: 0.25,
        opacity: 0,
        pointerEvents: "none"
      });
    }
  }, [showMenu]);

  useEffect(() => {
    toggleMenu(false);
    if (dimensions.width > 1079) {
      tl.to(`#mobile-menu`, {
        duration: 0.0,
        opacity: 1,
        pointerEvents: "unset"
      }).to(`.mobile__content`, {
        duration: 0.0,
        opacity: 1
      });
    } else {
      tl.to(`.mobile__content`, {
        duration: 0,
        opacity: 0
      }).to(`#mobile-menu`, {
        duration: 0,
        opacity: 0,
        pointerEvents: "none"
      });
    }
  }, [dimensions]);
  //EFFECTS

  return (
    <>
      <HeaderContainer
        isBlack={isBlack ? black.default : white.default}
        className="navbar"
      >
        <div className="header__section">
          <Toggle
            className="toggle"
            onClick={() => {
              toggleMenu(!showMenu);
              if (!isBlack && showMenu && !isBG) {
                setBlack(true);
              } else if (showMenu && isBG) {
                setBlack(false);
              } else if (isBlack && !showMenu) {
                setBlack(false);
              }
            }}
            showMenu={showMenu}
          >
            <icons.Menu
              color={isBlack ? black.default : white.default}
              open={showMenu}
            />
          </Toggle>
          <MobileMenu id="mobile-menu">
            <div className="mobile__content">
              <Filters
                translations={translations}
                currentLocale={currentLocale}
                skillFilter={skillFilter}
                setSkillFilter={setSkillFilter}
                data={data}
                setBlack={setBlack}
                toggleMenu={toggleMenu}
                isHome={isHome}
              />
              <div className="show__mobile">
                <Menu currentLocale={currentLocale} data={data} pID={pID} />
              </div>
            </div>

            <div className="mobile__footer">
              <Smedia>
                <li>
                  <a
                    href="https://www.instagram.com/theplaygroundpt/"
                    aria-label="Social Media Vimeo"
                  >
                    <Facebook color={isBlack ? black.default : white.default} />
                  </a>
                </li>

                <li>
                  <a
                    href="https://www.instagram.com/theplaygroundpt/"
                    aria-label="Social Media Vimeo"
                  >
                    <Instagram
                      color={isBlack ? black.default : white.default}
                    />
                  </a>
                </li>

                <li>
                  <a
                    href="https://www.instagram.com/theplaygroundpt/"
                    aria-label="Social Media Vimeo"
                  >
                    <LinkedIn color={isBlack ? black.default : white.default} />
                  </a>
                </li>
              </Smedia>

              <p>© {getYear()} PLAYGROUND - Production Company.</p>
            </div>
          </MobileMenu>
        </div>

        <LogoContainer className="logo-container">
          <Link
            to={currentLocale === "PT" ? "/" : "/en"}
            onClick={() => {
              setSkillFilter("");
            }}
            aria-label="Go to Homepage"
          >
            <icons.Logo color={isBlack ? black.default : white.default} />
          </Link>
        </LogoContainer>
        <div className="header__section end">
          <div className="hide__mobile">
            <Menu currentLocale={currentLocale} data={data} pID={pID} />
          </div>
          {!!translations && (
            <Lang translations={translations} currentLocale={currentLocale} />
          )}
        </div>
      </HeaderContainer>
    </>
  );
};

export default Header;
