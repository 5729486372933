import React from "react";

const Menu = (props) => {
  const { color, open } = props;

  return (
    <svg
      className="svg-anim"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      style={{ transform: !open ? "" : "rotate(90deg)" }}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="4" height="4" fill={color} />
      <rect y="11" width="4" height="4" fill={color} opacity={!open ? 1 : 0} />
      <rect y="22" width="4" height="4" fill={color} />
      <rect x="11" width="4" height="4" fill={color} opacity={!open ? 1 : 0} />
      <rect x="11" y="11" width="4" height="4" fill={color} />
      <rect
        x="11"
        y="22"
        width="4"
        height="4"
        fill={color}
        opacity={!open ? 1 : 0}
      />
      <rect x="22" width="4" height="4" fill={color} />
      <rect
        x="22"
        y="11"
        width="4"
        height="4"
        fill={color}
        opacity={!open ? 1 : 0}
      />
      <rect x="22" y="22" width="4" height="4" fill={color} />
    </svg>
  );
};

export default Menu;
