import React, { useState, useEffect } from "react";
// import Helmet from "react-helmet";

import gsap from "gsap";
import { GlobalStyles } from "theme/global";
import { CookiesProvider } from "react-cookie";

import Header from "components/header";
import SocialMediaFooter from "components/socialMediaFooter";
import CreateHead from "../components/createHead/CreateHead";
import CookiesBar from "../components/cookieBar";

//UTILITY
function debounce(fn, ms) {
  let timer;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}
//UTILITY

const Layout = (props) => {
  //PROPS
  const {
    data,
    location,
    children: {
      props: { pageContext }
    }
  } = props;
  //PROPS

  //DEFS
  const tl = gsap.timeline();
  let locale = pageContext?.language?.code;
  let translations = pageContext?.translations
    ? pageContext?.translations[0]
    : null;
  //DEFS

  //STATE
  const [skillFilter, setSkillFilter] = useState("");
  const [isBlack, setBlack] = useState(true);
  const [dimensions, setDimension] = useState({
    height: "",
    width: ""
  });
  const [load, setLoad] = useState(false);
  const [showLogo, setLogo] = useState(false);
  const [showFooter, setFooter] = useState(true);
  const [cookieBarSpecial, setCookiesSpecial] = useState(false);
  //STATE

  //FUNCS

  const toTop = () => {
    load &&
      tl.to(`.logo-container`, {
        duration: 0.25,
        pointerEvents: "none",
        opacity: 0
      });
  };

  const toBot = () => {
    load &&
      tl.to(`.logo-container`, {
        duration: 0.25,
        pointerEvents: "unset",
        opacity: 1
      });
  };

  const [scrollTop, setScroll] = useState(true);

  useEffect(() => {
    //console.log(showLogo, scrollTop);
    if (showLogo) {
      toBot();
    } else {
      scrollTop ? toBot() : toTop();
    }
  }, [scrollTop, showLogo]);

  useEffect(() => {
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;
      scrollY > 10 ? setScroll(false) : setScroll(true);

      ticking = false;
      if (
        scrollY > 300 &&
        pageContext &&
        pageContext.title === "Homepage" &&
        window.innerHeight + window.scrollY < document.body.offsetHeight - 50
      ) {
        setFooter(false);
      } else if (
        window.innerHeight + window.scrollY >=
          document.body.offsetHeight - 50 &&
        pageContext &&
        pageContext.title === "Homepage"
      ) {
        setFooter(true);
      }

      return;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    //console.log("adicionar");
    window.addEventListener("scroll", onScroll, true);

    return () => {
      //console.log("saiu");
      window.removeEventListener("scroll", onScroll, true);
    };
  }, [pageContext.title]);
  //FUNCS

  //EFFECT
  useEffect(() => {
    gsap.to("body", {
      delay: 1,
      duration: 0.4,
      css: { opacity: "1" }
    });

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    function isTouchDevice() {
      return (
        "ontouchstart" in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0 ||
        window.innerWidth < 600
      );
    }

    const debouncedHandleResize = debounce(function handleResize() {
      setDimension({
        height: window.innerHeight,
        width: window.innerWidth,
        isMobile: isTouchDevice()
      });
    }, 100);

    window.addEventListener("resize", debouncedHandleResize);

    setDimension({
      height: window.innerHeight,
      width: window.innerWidth
    });

    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, []);

  useEffect(() => {
    location.state?.skillFilter && setSkillFilter(location.state?.skillFilter);
    setLoad(true);
    setLogo(false);
    setFooter(true);
    return () => {
      toBot();
      setLoad(false);
      setCookiesSpecial(false);
    };
  }, [location]);
  //EFFECT

  //CHILDREN MODIFIER
  const childrenWithProps = React.Children.map(props.children, (child) => {
    return React.cloneElement(child, {
      skills: skillFilter,
      isBlack: isBlack,
      setBlack: setBlack,
      setSkillFilter: setSkillFilter,
      dimensions: dimensions,
      locale: locale,
      setLogo: setLogo,
      setCookiesSpecial: setCookiesSpecial
    });
  });
  //CHILDREN MODIFIER

  // console.log(pageContext?.template?.templateName);

  return (
    <CookiesProvider>
      <GlobalStyles />
      <CreateHead
        code={locale}
        title={props.pageContext?.seo?.seoTitle}
        image={props.pageContext?.seo?.seoImage}
        description={props.pageContext?.seo?.seoDescription}
      />
      {load && (
        <>
          <Header
            setBlack={setBlack}
            data={data}
            dimensions={dimensions}
            isBG={
              pageContext?.template?.templateName === "Homepage Template" ||
              pageContext?.template?.templateName === "Studio Template" ||
              pageContext?.template?.templateName ===
                "Shooting In Portugal Template"
            }
            isHome={pageContext?.template?.templateName === "Homepage Template"}
            pID={pageContext?.parentId ? true : null}
            currentLocale={locale}
            skillFilter={skillFilter}
            setSkillFilter={setSkillFilter}
            translations={translations}
            isBlack={isBlack}
            setScroll={setScroll}
          />
          {childrenWithProps}
          <SocialMediaFooter
            isBlack={isBlack}
            dimensions={dimensions}
            locale={locale}
            showFooter={showFooter}
            isTalentPage={props.data?.page?.talents_info ? true : false}
            isHome={pageContext?.template?.templateName === "Homepage Template"}
          />
          <CookiesBar
            isBlack={cookieBarSpecial ? true : isBlack}
            locale={locale}
          />
        </>
      )}
    </CookiesProvider>
  );
};

export default Layout;
