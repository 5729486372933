export const white = {
	default: 'rgb(255, 253, 247)'
};

export const black = {
	default: 'rgb(0, 0, 0)'
};

export const blue = {
	default: '#3B458E',
	light: 'rgb(97, 204, 255)'
};
