import React from "react";

const Play = (props) => {
  const { color } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.74 11.96">
      <path
        fill={color}
        d="M0 11.96V0l10.74 5.98L0 11.96zM1 1.7v8.55l7.69-4.28L1 1.7z"
      />
    </svg>
  );
};

export default Play;
