import React from "react";

const ShareButton = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 16">
      <defs />
      <defs>
        <filter id="a">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
          />
        </filter>
      </defs>
      <g fill="none" stroke="#000">
        <g filter="url(#a)" transform="matrix(0 -1 1 0 2.999999 12.5)">
          <path d="M6.5.38479263l5 4-5 4" />
          <path strokeLinecap="square" d="M10.29032257 4.3847926h-10" />
        </g>
        <path d="M0 8.5v7h15v-7" />
      </g>
    </svg>
  );
};

export default ShareButton;
