import styled from "styled-components";

import { menuItem } from "theme/typography";
import { black, white } from "theme/colors";
import { toRem } from "utils/mixins";
import { breakpoints, zIndex } from "utils/breakpoints";

export const HeaderContainer = styled.header`
  position: fixed;
  height: ${toRem(90)};
  padding: ${toRem(20)} ${toRem(20)} 0;
  width: 100%;
  z-index: ${zIndex.mid};
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  color: ${(props) => props.isBlack};
  .header__section {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 33%;
    &.end {
      justify-content: flex-end;
    }
    ul {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      li {
        &.active {
          button,
          a {
            position: relative;
            font-weight: 500;
            &:after {
              width: 100%;
              left: 0;
            }
          }
        }
      }
    }
    button,
    a {
      ${menuItem}
      display: block;
      position: relative;
      cursor: pointer;
      transition: 250ms;
      &:after {
        content: "";
        position: absolute;
        width: 0;
        height: ${toRem(1)};
        display: block;
        margin-top: 0;
        right: 0;
        background-color: ${(props) => props.isBlack};
        transition: 0.4s ease;
      }
      &:hover {
        opacity: 1;
        //opacity: 0.2;
        &:after {
          width: 100%;
          left: 0;
        }
      }
    }
    .lang-item {
      /* font-weight: 300;
      margin: 0;
      margin-left: 10px; */
      font-size: ${toRem(16)};
      line-height: ${toRem(17)};
      letter-spacing: -0.67px;
      /* &--active {
        font-weight: 500;
        position: relative;
        &:after {
          content: "";
          position: absolute;
          height: ${toRem(1)};
          display: block;
          margin-top: 0;
          width: 100%;
          left: 0;
          background-color: ${(props) => props.isBlack};
          transition: 0.4s ease;
        }
      } */

      @media ${breakpoints.forLaptop} {
        ${menuItem}
      }
    }
  }

  @media ${breakpoints.forTablet} {
    height: ${toRem(120)};
  }

  @media ${breakpoints.forLaptop} {
    align-items: center;
    padding: ${toRem(20)} ${toRem(45)} 0;
    height: ${toRem(90)};

    .header__section {
      &.mid {
        svg {
          height: ${toRem(70)};
          width: ${toRem(70)};
        }
      }
      ul {
        li {
          margin-right: ${toRem(12)};

          &.active {
            button,
            a {
              &::after {
                height: ${toRem(1)};
              }
            }
          }
        }
      }
    }
  }

  @media ${breakpoints.forDesktop} {
    padding: ${toRem(20)} ${toRem(63)} 0;
    height: ${toRem(120)};
    .header__section {
      &.mid {
        svg {
          height: ${toRem(98)};
          width: ${toRem(98)};
        }
      }
      ul {
        li {
          margin-right: ${toRem(15)};
        }
      }
    }
  }

  @media ${breakpoints.forBigDesktop} {
    height: 13vh;
    padding: 3vh 6vh 0;
  }
`;

export const Toggle = styled.div`
  display: block;
  position: relative;
  padding: 5px;
  transform: translate(-5px, -5px);
  z-index: ${zIndex.more};
  color: ${(props) => (props.showMenu ? white.default : black.default)};
  height: ${toRem(30)};
  width: ${toRem(30)};
  svg {
    height: ${toRem(20)};
    width: ${toRem(20)};
  }
  @media ${breakpoints.forTablet} {
    height: ${toRem(36)};
    width: ${toRem(36)};
    svg {
      height: ${toRem(26)};
      width: ${toRem(26)};
    }
  }
  @media ${breakpoints.forLaptop} {
    display: none;
  }
`;

export const MobileMenu = styled.div`
  height: 100vh;
  width: 100vw;
  background: black;
  color: ${white.default};
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  padding: ${toRem(20)};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  /* transition: ${(props) =>
    props.showMenu ? "transform 1s, color 0s" : "transform 1s"};
  transform: ${(props) =>
    props.showMenu ? "translateY(0)" : "translateY(-100%)"}; */
  .mobile__content {
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      li {
        a {
          transition: 0s !important;
        }
        margin-bottom: ${toRem(10)};
        text-align: center;
      }
    }
  }
  .mobile__footer {
    position: absolute;
    left: 0;
    bottom: ${toRem(20)};
    width: 100%;
    text-align: center;
    p {
      text-align: center;
      min-height: 15px;
    }
  }
  @media ${breakpoints.forLaptop} {
    position: relative;
    padding: 0;
    height: auto;
    width: auto;
    background: transparent;
    color: inherit;
    left: unset;
    top: unset;
    display: flex;
    transform: none;
    .mobile__footer {
      display: none;
    }
    .mobile__content {
      justify-content: flex-start;
      ul {
        flex-direction: row;
        justify-content: flex-start;
        li {
          margin-bottom: 0;
          text-align: center;
        }
      }
    }
  } ;
`;

export const LogoContainer = styled.div`
  position: absolute;
  background-color: transparent;
  top: ${toRem(20)};
  left: 50%;
  transform: translateX(-50%);
  z-index: ${zIndex.more};
  justify-content: center;

  svg {
    height: ${toRem(70)};
    width: ${toRem(70)};
  }
  @media ${breakpoints.forTablet} {
    svg {
      height: ${toRem(100)};
      width: ${toRem(100)};
    }
  }
  @media ${breakpoints.forLaptop} {
    svg {
      height: ${toRem(70)};
      width: ${toRem(70)};
    }
  }
  @media ${breakpoints.forDesktop} {
    svg {
      height: ${toRem(98)};
      width: ${toRem(98)};
    }
  }
  @media ${breakpoints.forBigDesktop} {
    top: 3vh;
    svg {
      height: 10vh;
      width: 10vh;
    }
  }
`;

export const Smedia = styled.ul`
  width: 100%;
  align-items: center !important;
  justify-content: center !important;
  display: flex !important;
  margin-bottom: ${toRem(20)};
  li {
    margin: 0 ${toRem(10)};
    a {
      font-size: unset !important;
      line-height: unset !important;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4px;
      height: ${toRem(28)};
      &::after {
        display: none !important;
      }
      svg {
        height: ${toRem(20)};
      }
    }
  }

  @media ${breakpoints.forLaptop} {
    margin: 0 ${toRem(24)};
  }
`;
