import styled from "styled-components";

import { toRem } from "utils/mixins";
import { p } from "theme/typography";
import { white, black } from "theme/colors";
import { breakpoints } from "utils/breakpoints";

export const FooterWrapper = styled.footer`
  position: ${(props) =>
    props.isHome ? "fixed" : props.isTal ? "absolute" : "relative"};
  bottom: 0;
  left: 0;
  display: flex;
  align-items: flex-end;

  justify-content: center;
  width: 100%;
  color: ${(props) => (props.isBlack ? black.default : white.default)};
  padding: ${toRem(20)};
  margin-top: ${toRem(70)};
  z-index: ${(props) => (props.isHome || props.isTal ? "5" : "")};
  transition: 250ms;
  opacity: ${(props) => (!props.showFooter ? "0" : "1")};
  pointer-events: ${(props) => (!props.showFooter ? "none" : "unset")};
  @media ${breakpoints.forBigTablet} {
    justify-content: space-between;
  }
`;

export const Copy = styled.p`
  width: calc(100% / 3);
  text-align: center;
  font-size: ${toRem(10)};
  margin-bottom: 0;
  font-weight: 400;
  line-height: ${toRem(12)};
  display: none;
  @media ${breakpoints.forBigTablet} {
    display: block;
  }
`;

export const Smedia = styled.ul`
  width: calc(100% / 3);
  align-items: center;
  justify-content: flex-end;
  display: none;
  li {
    margin: 0 ${toRem(10)};
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4px;
      height: ${toRem(28)};
      svg {
        height: ${toRem(20)};
      }
    }
  }

  @media ${breakpoints.forBigTablet} {
    display: flex;
  }
  @media ${breakpoints.forLaptop} {
    li {
      margin: 0 ${toRem(24)};
    }
  }
`;

export const Newsletter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  margin-top: ${toRem(20)};
  color: ${(props) => (props.isBlack ? black.default : white.default)};
  .news_container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: ${(props) =>
      props.isBlack
        ? toRem(1) + " solid " + black.default
        : toRem(1) + " solid " + white.default};
    width: ${toRem(300)};
    font-size: ${toRem(14)};
    padding: 0;
    margin: 0;
    line-height: ${toRem(19)};
    .news_feedback {
      background-color: ${white.default};
      padding: ${toRem(15)};
      transform: translateX(100%);
      position: absolute;
      right: 0;
    }
  }

  *:focus {
    outline: none;
  }

  input {
    width: ${toRem(300)};
    border: none;
    background-image: none;
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    text-align-last: left;

    color: ${(props) => (props.isBlack ? black.default : white.default)};
    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      ${p}

      color: ${(props) => (props.isBlack ? black.default : white.default)};
      font-size: ${toRem(14)};
      opacity: 0.6;
      text-align-last: left;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      ${p}

      color: ${(props) => (props.isBlack ? black.default : white.default)};
      font-size: ${toRem(14)};
      opacity: 0.6;
      text-align-last: left;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      ${p}

      color: ${(props) => (props.isBlack ? black.default : white.default)};
      font-size: ${toRem(14)};
      opacity: 0.6;
      text-align-last: left;
    }
  }

  @media ${breakpoints.forBigTablet} {
    width: calc(100% / 3);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 0;
    .news_container {
      width: 90%;
    }

    input {
      width: 90%;
    }
  }
  @media ${breakpoints.forLaptop} {
    .news_container {
      width: ${toRem(300)};
    }
    input {
      width: ${toRem(300)};
    }
  }
`;
