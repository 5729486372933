import React from "react";

const Arrow = (props) => {
  const { color } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="21">
      <g>
        <g fill="none" stroke={color}>
          <path d="M20.25 0l10.5 10.5L20.25 21" />
          <path strokeLinecap="square" d="M30.5 10.499999H.5" />
        </g>
      </g>
    </svg>
  );
};

export default Arrow;
