import { Link } from "gatsby";
import React from "react";

import { useCookies } from "react-cookie";

import { CookiesBarContainer } from "./CookiesBar.styles";

const CookiesBar = (props) => {
  //PROPS
  const { isBlack, locale } = props;
  //PROPS

  //COOKIES
  const [cookies, setCookie] = useCookies(["hasCookiesPlay"]);
  //COOKIES

  //FUNCS
  const handleCookie = () => {
    setCookie("hasCookiesPlay", "true", { path: "/" });
  };

  function deleteAllCookies() {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }

  //FUNCS

  //DEFS
  let isPt = locale === "PT";
  //DEFS

  return (
    <>
      {cookies.hasCookiesPlay !== "true" && (
        <CookiesBarContainer isBlack={isBlack}>
          <p>Cookies</p>
          <button onClick={() => handleCookie()}>
            {isPt ? "Aceitar" : "Accept"}
          </button>
          <button onClick={() => deleteAllCookies()}>
            {isPt ? "Rejeitar" : "Deny"}
          </button>
          <Link to={isPt ? "/politica-de-privacidade" : "/en/privacy-policy"}>
            {isPt ? "Saber mais" : "Learn More"}
          </Link>
        </CookiesBarContainer>
      )}
    </>
  );
};

export default CookiesBar;
