import styled from "styled-components";

import { toRem } from "utils/mixins";
import { breakpoints } from "utils/breakpoints";
import { black, white } from "theme/colors";

export const CookiesBarContainer = styled.div`
  width: auto;
  position: fixed;
  background: ${(props) => (props.isBlack ? white.default : "transparent")};
  z-index: 8;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 5px;
  border: 1px solid
    ${(props) => (props.isBlack ? black.default : white.default)};

  p,
  button,
  a {
    white-space: nowrap;
    display: inline-block;
    position: relative;
    margin: 0 8px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 300;
    color: ${(props) => (props.isBlack ? black.default : white.default)};
    &:after {
      content: "";
      position: absolute;
      width: 0;
      height: ${toRem(1)};
      display: block;
      margin-top: 0;
      right: 0;
      background-color: ${(props) =>
        props.isBlack ? black.default : white.default};
      transition: 0.4s ease;
    }
    &:hover {
      //opacity: 0.2;
      &:after {
        width: 100%;
        left: 0;
      }
    }
  }

  p {
    font-weight: 500;
    text-transform: uppercase;
    &:after {
      width: 100%;
      left: 0;
    }
  }

  button {
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: calc(100% + 8px);
      height: 2px;
      width: 2px;
      background-color: ${(props) =>
        props.isBlack ? black.default : white.default};
    }
  }
  @media ${breakpoints.forBigTablet} {
    padding: 5px 10px;
    p,
    button,
    a {
      margin: 0 20px;
    }
    button {
      &::before {
        left: calc(100% + 20px);
      }
    }
  }
`;
