import React from "react";
import { Helmet } from "react-helmet";

const CreateHead = (props) => {
  //PROPS
  const { title, image, description, url } = props;
  //PROPS

  //DEFS
  // !!TODO : base info + lang pack + fonts
  const headInfo = {
    title: title ? title : "The Playground",
    description: description ? description : "Lisbon based production company",
    url: url ? url : "https://theplayground.pt",
    image: image ? image.localFile?.url : "/images/holy-mossa.png"
  };
  //DEFS

  return (
    <Helmet
      htmlAttributes={{
        lang: props.code === "EN" ? "en" : "pt"
      }}
    >
      <title>{headInfo.title}</title>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="description" content={headInfo.description} />
      <meta name="keywords" content="Movie, Video, Photography, Ads, Publicidade" />

      <link rel="apple-touch-icon" sizes="76x76" href="/apple-touch-icon.png" />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/fav/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/fav/favicon-16x16.png"
      />
      <link rel="manifest" href="/fav/site.webmanifest" />
      <link rel="mask-icon" href="/fav/safari-pinned-tab.svg" color="#000000" />
      <meta name="msapplication-TileColor" content="#fffdf7" />
      <meta name="theme-color" content="#ffffff" />

      <meta property="og:url" content={headInfo.url} />
      <meta property="og:title" content={headInfo.title} />
      <meta property="og:description" content={headInfo.description} />
      <link rel="image_src" href={headInfo.image}></link>
      <meta property="og:image" content={headInfo.image} />

      <meta property="og:site_name" content={headInfo.title} />

      <meta name="twitter:title" content={headInfo.title} />
      <meta name="twitter:description" content={headInfo.description} />
      <meta name="twitter:image" content={headInfo.image} />
      <meta name="twitter:card" content="summary_large_image"></meta>
      <meta name="twitter:image:alt" content={headInfo.title}></meta>
      {/* <link as="font" href="/fonts/Sneak-Medium.woff2" rel="preload" crossorigin="anonymous"/> 
      <link as="font" href="/fonts/Sneak-Light.woff2" rel="preload" crossorigin="anonymous"/>  */}
      <link
        rel="stylesheet"
        type="text/css"
        charset="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      {/* <script
        src="https://cdnjs.cloudflare.com/ajax/libs/gsap/3.5.1/gsap.min.js"
        integrity="sha512-IQLehpLoVS4fNzl7IfH8Iowfm5+RiMGtHykgZJl9AWMgqx0AmJ6cRWcB+GaGVtIsnC4voMfm8f2vwtY+6oPjpQ=="
        crossorigin="anonymous"
      ></script> */}
      {props.children}
    </Helmet>
  );
};
export default CreateHead;
