module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/layout/index.js"},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"custom":[{"name":["Sneak"],"file":"/fonts/stylesheet.css"}]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://staging.theplayground.pt/graphql","verbose":true,"schema":{"queryDepth":6,"typePrefix":"Wp","perPage":100,"timeout":500000000,"circularQueryLimit":5,"requestConcurrency":15,"previewRequestConcurrency":5},"develop":{"nodeUpdateInterval":800000,"hardCacheMediaFiles":false,"hardCacheData":false},"production":{"hardCacheMediaFiles":false,"allow404Images":false},"type":{"MediaItem":{"localFile":{"requestConcurrency":50,"excludeByMimeTypes":[],"maxFileSizeBytes":15728640},"lazyNodes":false},"IsWorkService":{"exclude":true},"IsStudio":{"exclude":true},"TagWorks":{"exclude":true},"TagTalents":{"exclude":true},"LocationType":{"exclude":true},"DominantColorTag":{"exclude":true},"CastingType":{"exclude":true},"LocationTag":{"exclude":true},"CastingTag":{"exclude":true},"CastingNameTag":{"exclude":true},"CastingIsCoverTag":{"exclude":true},"WorksCategoriesTag":{"exclude":true},"WorksNameTag":{"exclude":true},"TalentsTag":{"exclude":true},"NewsTag":{"exclude":true},"LocationNameTag":{"exclude":true},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"debug":{"graphql":{"showQueryOnError":true,"showQueryVarsOnError":true,"copyQueryOnError":true,"panicOnError":false,"onlyReportCriticalErrors":true,"copyNodeSourcingQueryAndExit":false,"writeQueriesToDisk":false,"printIntrospectionDiff":false},"preview":false,"timeBuildSteps":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
