import React from "react";

const LinkedIn = (props) => {
  const { color } = props;
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.34734 10.4988C7.34734 8.75895 7.29109 7.3043 7.23422 6.04868H10.9473L11.1455 7.98948H11.2298C11.7917 7.07549 13.1705 5.73556 15.4773 5.73556C18.2898 5.73556 20.3998 7.64657 20.3998 11.7551V20H16.1242V12.2685C16.1242 10.4716 15.5042 9.24447 13.9573 9.24447C12.7767 9.24447 12.0742 10.0716 11.7642 10.8703C11.6511 11.1555 11.623 11.5548 11.623 11.9547V20H7.34734V10.4988ZM0.512966 19.9987H4.78859V6.04868H0.512966V19.9987ZM4.92859 2.16835C4.92859 3.36629 4.05609 4.33606 2.62172 4.33606C1.27172 4.33606 0.399841 3.36629 0.399841 2.16835C0.399841 0.941877 1.30109 0 2.67859 0C4.05609 0 4.90047 0.941877 4.92859 2.16835Z"
        fill={color}
      />
    </svg>
  );
};

export default LinkedIn;
