import React from "react";

const Logo = (props) => {
  const { color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 314.24 315.46"
      fill={color}
    >
      <defs />
      <path d="M29.71 157.61c16.54 0 30.23 9.56 30.23 33.07v21.17h30.49v13.18H0v-34.35c0-23.51 13.57-33.07 29.71-33.07zm0 13.17c-10.08 0-18.47 5.43-18.47 18.22v22.85H48.7V189c0-12.92-9.04-18.22-18.99-18.22zM78.68 90.43h11.76v53.89H0v-13.57h78.68V90.43zM150.25 68.21h-38.37l-7.75 22.22h-13.7L123.25 0h15.63l32.81 90.43H158l-7.75-22.22zm-3.88-10.98l-15.24-43.41-15.37 43.41h30.61zM243.43 0L198.6 90.43h-14.86l17.44-34.75L164.63 0h16.15l27.13 42.63L228.71 0h14.72z" />
      <g>
        <path d="M268.77 144.3h-46.25v-8.78l15.76-1.42c-10.21-5.04-17.05-14.34-17.05-28.94 0-26.23 18.6-42.76 46.25-42.76 28.03 0 46.77 16.92 46.77 43.28 0 22.48-12.01 36.56-31.91 38.11v-12.92c13.05-1.81 20.41-11.37 20.41-25.19 0-18.6-14.73-29.97-35.14-29.97-20.15 0-35.01 11.11-35.01 31.01 0 14.6 9.3 25.58 25.97 25.58v-30.88h10.21v42.88zM222.52 225.03v-14.21l20.67-6.72c15.24-4.91 13.82-13.18 13.82-33.46h-34.49v-13.05h90.43v31.26c0 23.77-13.82 32.04-27 32.04-11.5 0-20.8-5.81-24.03-19.77-2.07 8.27-6.85 13.69-17.96 17.18l-21.44 6.73zm78.81-54.39h-33.07v19.77c0 12.4 8.78 17.18 16.79 17.18 8.14 0 16.28-4.91 16.28-17.18v-19.77z" />
      </g>
      <g>
        <path d="M148.49 315.46v-90.43h16.28l44.83 75.06v-75.06h12.92v90.43h-16.41l-44.7-75.06v75.06h-12.92zM62.4 270.24c0-26.87 13.82-45.22 44.7-45.22h28.11v90.43H107.1c-30.62.01-44.7-18.34-44.7-45.21zm13.69.13c0 17.18 5.81 33.72 32.43 33.72h13.38V236.4h-13.38c-25.97-.01-32.43 16.14-32.43 33.97z" />
      </g>
    </svg>
  );
};

export default Logo;
